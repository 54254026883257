import Swiper from 'swiper';






var swiper = new Swiper ('.swiper-container', {
	loop: true,
	effect: 'fade',
	mousewheel: true,
	keyboard: true,
	fadeEffect: {
		crossFade: true
	},
	on: {
		click: function() {
			swiper.slideNext();
		},
		slideChangeTransitionEnd: function () {

			var currentSlide = document.getElementsByClassName('swiper-slide-active')[0];
			var prevSlide = document.getElementsByClassName('swiper-slide-prev')[0];
			var nextSlide = document.getElementsByClassName('swiper-slide-next')[0];

			if(prevSlide.classList.contains("video")) {
				var videoEl = prevSlide.getElementsByTagName('video')[0];
				videoEl.pause();
				videoEl.currentTime = 0;
			}
			if(nextSlide.classList.contains("video")) {
				var videoEl = nextSlide.getElementsByTagName('video')[0];
				videoEl.pause();
				videoEl.currentTime = 0;
			}
			if(currentSlide.classList.contains("video")) {
				var videoEl = currentSlide.getElementsByTagName('video')[0];
				videoEl.muted = true;
				videoEl.play();
			}
		}
	}
  });


// open dropdown menu
$('body').on('click', '.project-nav > div > span', function() {
	$(this).parent().toggleClass('opened').siblings().removeClass('opened');
  });
  
